import React from 'react'
import Img from 'gatsby-image'
import Image from '../../../components/image'
import moment from 'moment';
import {
    TimelineWrap, 
    TimelineList,
    Line,
    Item,
    Dots,
    TimelineRow,
    TimelineFeature,
    TimelineFeatureInner,
    TimelineInfo,
    TimelineInfoInner,
    DateWrap,
    PhotoContainer,
    TimelineContent,
    Title,
    Descript
} from './style'

const Timeline = ({ items }) => {
    return (
        <TimelineWrap>
            <TimelineList>
                <Line />
                {items && items.map((_item, i) => {

                    const item = _item.node ? _item.node.data : null;

                    if(item != null){
                        const { Address, BookingUrl, Cost, Day, DayOrder, Description, InfoProviders, Location, Period, Photo, PriceNotIncluded, Time, TripName, WatchUrl} = item;

                        const desc = Description

                        const gatsbyImg = Photo && Photo[0].url;

                        const imageSrc = gatsbyImg || Photo;
                        let boxImage;
                        if (imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
                            boxImage = <Img fixed={imageSrc.fixed} alt="popup video" />;
                        } else if (imageSrc.fluid) {
                            boxImage = <Image fluid={imageSrc.fluid} alt="popup video" />
                        } else {
                            boxImage = <img src={imageSrc} alt="popup video" />
                        }
                        return (
                            <Item key={`timeline-${i}`} align={i % 2 === 0 ? 'left' : 'right'}>
                                <Dots />
                                <TimelineRow>
                                    <TimelineFeature>
                                        <TimelineFeatureInner>
                                            {Location && <Title>{Location}</Title>}
                                            {imageSrc && <PhotoContainer>{boxImage}</PhotoContainer>}
                                        </TimelineFeatureInner>
                                        <TimelineInfo>
                                        <TimelineInfoInner>
                                            <TimelineContent>
                                                {Time && <DateWrap>{moment(Time).format("hh:mm a")}</DateWrap>}
                                                {Description && <Descript>{Description}</Descript>}
                                            </TimelineContent>
                                        </TimelineInfoInner>
                                    </TimelineInfo>
                                    </TimelineFeature>
                                    
                                </TimelineRow>
                            </Item>
                        )    
                    }

                    
                })}

            </TimelineList>
        </TimelineWrap>
    )
}

export default Timeline
